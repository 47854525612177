/* ======================================================================= */
/* ========================= >>> VARIÁVEIS <<< =========================== */
/* ======================================================================= */

// CORES
$cor_01: #022857;
$cor_02: #00AEEF;
$cor_03: #87c730;
$cor_04: #67a600;
$cor_05: #7540a8;
$cor_06: #4b1499;
$cor_07: #f78e0f;
$cor_08: #ea7f0a;
$cor_09: #2777c1;
$cor_10: #0a568e;
$cor_11: #ffc10e;
$cor_12: #CC1746;
$cor_13: #00adef;
$cor_14: #3F4856;
$cor_15: #00AEEF;
$cor_16: #a9e1ef;
$cor_17: #ACFF0D;
$cor_18: #FF6901;
$cor_19: #FF6901;
$cor_text: #7389A9;
$radio_color_01: #f4f4f4;
$radio_color_02: #3197EE;
$screen_ss: 375px;
$screen_xs: 767px;
$screen_sm: 768px;
$screen_md: 992px;
$screen_desktop_sm: 1270px;
$screen_desktop_md: 1350px;
$screen_desktop_lg: 1430px;
$tooltip-bg: $cor_09;
$zindex-tooltip: 999999;
